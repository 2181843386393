.box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  p.label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
  }

  .input {
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 16px;
    padding: 14px 16px;
    width: 100%;
  }
  p.error{
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: red;
  }
}