.wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  > div {
    flex-basis: 33.333333%;
  }

  svg,
  img {
    width: 20px;
    height: 20px;
  }
}
