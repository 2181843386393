.wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #757575;
    }

    .head {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .sliderWrap {
        position: relative;
        width: 100%;
        margin: 12px 0;
    }

    .rangeSlider {
        -webkit-appearance: none; /* Override default CSS styles */
        appearance: none;
        width: 100%; /* Full-width */
        height: 4px; /* Specified height */
        background: #dfdfdf; /* Grey background */
        outline: none; /* Remove outline */
        opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
        transition: opacity 0.2s;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 20px;
            height: 20px;
            background: #ffffff;
            border-radius: 50%;

            border: 2px solid #333333;

            box-shadow: 0px 0px 30px rgba(51, 51, 51, 0.11);
            cursor: pointer;
        }
    }

    output {
        position: absolute;
        top: -25px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        left: 0;
        white-space: nowrap;
        color: #333333;
    }
}