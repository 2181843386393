@import "src/styles/mixin";

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 0.5;

  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.11);
  border-radius: 20px;
  padding: 24px;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
  }

  @include tablet-m {
    width: 100%;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
  }

  input {
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 16px;
    padding: 14px 16px;
    width: 100%;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #757575;
    text-align: left;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333333;
  transition: 0.2s;
  border-radius: 16px;
  ont-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  padding: 16px 0;
  width: 100%;

  &:disabled {
    opacity: 0.2;
  }
}

.error {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #ff0000;
}
