@import "src/styles/mixin";

.block {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.11);
    border-radius: 20px;
    padding: 24px;
    flex: 1;
    gap: 20px;

    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #333333;
        align-self: flex-start;
    }

    @include tablet-m {
        width: 100%;
    }


    &__chips {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    &__row {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
    }

    &__result {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            p.label {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #757575;
            }
        }

        @include tablet-m {
            flex-direction: column;
            gap: 20px;
        }
    }


    &__contact {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__fields {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 20px;

        > div {
            flex: 1;
        }
    }

    &__submit{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #333333;
        transition: 0.2s;
        border-radius: 16px;
        ont-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff;
        padding: 16px 0;
        width: 100%;

        &:disabled {
            opacity: 0.2;
        }
    }

    &__info{
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .notice{
        color: #757575;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.42px;
    }

}