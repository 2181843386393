@import "src/styles/mixin";
@import "src/styles/container";



.page{
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: stretch;
  @extend .container;
  @include tablet-m {
    flex-direction: column;
  }
}