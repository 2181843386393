@import "normalize.css";
@import "container.scss";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  background: #f5f5f5;
}

#root {
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}
