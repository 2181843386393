@import './src/styles/mixin';
@import './src/styles/container';

.header {
    background: #ffffff;
    box-shadow: 15px 0px 15px rgba(51, 51, 51, 0.06);
}

.wrap {
    @extend .container;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}

.eaLogo {
    width: 128.27px;
    height: 52px;

    @include tablet-m {
        width: 88px;
        height: auto;
    }
}

.logoBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.creditLogo {
    img {
        max-width: 180px;
        max-height: 90px;
        @include tablet-m {
            max-width: 100%;
            width: 88px;
            height: auto;
        }
    }
}

.phone {
    a {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: #333333;
    }

    @include tablet-m {
        display: none;
    }
}

.callToAction {
    display: none;

    @include tablet-m {
        display: flex;

        svg {
            height: 23px;
            width: 23px;
        }
    }
}
