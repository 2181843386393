@import "mixin";

.container {
  display: flex;
  margin: 0 auto;
  width: 100%;
  padding: 0 200px;

  @include tablet-l {
    padding: 0 40px;
  }

  @include mobile-xl {
    padding: 0 20px;
  }

  &_grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.app {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

#_next {
  min-height: 100%;
}
