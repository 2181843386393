@import "./src/styles/mixin.scss";

.card {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.11);
  border-radius: 20px;
  gap: 32px;

  img {
    max-width: 204px;
  }

  @include tablet-m {
    width: 100%;
  }
}

.modelName {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.cardPrice {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.priceName {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.price {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
}
