.wrap{
    display: flex;
    flex-direction: column;
    gap: 8px;

    p.label{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #757575;
    }
}