@import "mixin";
@import "container";
@import "main";
@import "normalize.css";



::-webkit-scrollbar {
    width: 5px;
}


::-webkit-scrollbar-track {
    background: #F5F5F5;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background:#333333;
    width: 5px;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.error{
    color: red !important;
}

.notice{
    @extend .container;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    padding-top: 40px;

}

