@import "src/styles/mixin";

.box{
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  cursor: pointer;
  border: 1px solid #757575;
  border-radius: 12px;
  padding: 8px 12px;
  user-select: none;

  &:hover {
    background: #333333;
    color: #ffffff;
  }

  &_active {
    background: #333333;
    color: #ffffff;
  }

  @include mobile-l {
    font-size: 14px;
  }

  input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}