@mixin mobile-s {
  @media screen and (max-width: 321px) {
    @content;
  }
}

@mixin mobile-m {
  @media screen and (max-width: 376px) {
    @content;
  }
}

@mixin mobile-l {
  @media screen and (max-width: 481px) {
    @content;
  }
}

@mixin mobile-xl {
  @media screen and (max-width: 541px) {
    @content;
  }
}

@mixin tablet-s {
  @media screen and (max-width: 668px) {
    @content;
  }
}

@mixin tablet-m {
  @media screen and (max-width: 800px) {
    @content;
  }
}

@mixin tablet-l {
  @media screen and (max-width: 1025px) {
    @content;
  }
}

@mixin laptop-s {
  @media screen and (max-width: 1281px) {
    @content;
  }
}

@mixin laptop-m {
  @media screen and (max-width: 1367px) {
    @content;
  }
}

@mixin laptop-l {
  @media screen and (max-width: 1441px) {
    @content;
  }
}

@mixin desktop-s {
  @media screen and (max-width: 1537px) {
    @content;
  }
}

@mixin desktop-m {
  @media screen and (max-width: 1601px) {
    @content;
  }
}
